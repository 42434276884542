export const amcRmBranchMaster = [
  { key: 'Ambala', value: 'AMBALA' },
  { key: 'Agartala', value: 'AGARTALA' },
  { key: 'Agra', value: 'AGRA' },
  { key: 'Ahmedabad', value: 'AHMEDABAD' },
  { key: 'Ajmer', value: 'AJMER' },
  { key: 'Ankleshwar', value: 'ANKLESHWAR' },
  { key: 'Akola', value: 'AKOLA' },
  { key: 'Alwar', value: 'ALWAR' },
  { key: 'Aligarh', value: 'ALIGARH' },
  { key: 'Allahabad', value: 'ALLAHABAD' },
  { key: 'Amaravathi', value: 'AMARAVATHI' },
  { key: 'Amritsar', value: 'AMRITSAR' },
  { key: 'Anand', value: 'ANAND' },
  { key: 'Anantapur', value: 'ANANTAPUR' },
  { key: 'Asansol', value: 'ASANSOL' },
  { key: 'Aurangabad', value: 'AURANGABAD' },
  { key: 'Alleppy', value: 'ALLEPPY' },
  { key: 'Azamgarh', value: 'AZAMGARH' },
  { key: 'Baroda', value: 'BARODA' },
  { key: 'Bareilly', value: 'BAREILLY' },
  { key: 'Bharuch', value: 'BHARUCH' },
  { key: 'Mumbai', value: 'MUMBAI' },
  { key: 'Berhampur', value: 'BERHAMPUR' },
  { key: 'Beetul', value: 'BEETUL' },
  { key: 'Belgaum', value: 'BELGAUM' },
  { key: 'Bhilwara', value: 'BHILWARA' },
  { key: 'Bhubaneswar', value: 'BHUBANESWAR' },
  { key: 'Bhagalpur', value: 'BHAGALPUR' },
  { key: 'Bilaspur', value: 'BILASPUR' },
  { key: 'Bhilai', value: 'BHILAI' },
  { key: 'Bikaner', value: 'BIKANER' },
  { key: 'Bokaro Steel City', value: 'BOKARO STEEL CITY' },
  { key: 'Balasore', value: 'BALASORE' },
  { key: 'Bangalore', value: 'BANGALORE' },
  { key: 'Barhampore (Wb)', value: 'BARHAMPORE (WB)' },
  { key: 'Bhopal', value: 'BHOPAL' },
  { key: 'Bankura', value: 'BANKURA' },
  { key: 'Begusarai', value: 'BEGUSARAI' },
  { key: 'Bhatinda', value: 'BHATINDA' },
  { key: 'Burdwan', value: 'BURDWAN' },
  { key: 'Bhavnagar', value: 'BHAVNAGAR' },
  { key: 'Bellary', value: 'BELLARY' },
  { key: 'Calcutta', value: 'CALCUTTA' },
  { key: 'Coimbatore', value: 'COIMBATORE' },
  { key: 'Chandigarh', value: 'CHANDIGARH' },
  { key: 'Chandrapur', value: 'CHANDRAPUR' },
  { key: 'Cuttack', value: 'CUTTACK' },
  { key: 'Calicut', value: 'CALICUT' },
  { key: 'Kochi', value: 'KOCHI' },
  { key: 'Chinsura', value: 'CHINSURA' },
  { key: 'Dhanbad', value: 'DHANBAD' },
  { key: 'Darbhanga', value: 'DARBHANGA' },
  { key: 'Dewas', value: 'DEWAS' },
  { key: 'Dindigul', value: 'DINDIGUL' },
  { key: 'Dhule', value: 'DHULE' },
  { key: 'Dharwad', value: 'DHARWAD' },
  { key: 'Dehradun', value: 'DEHRADUN' },
  { key: 'Delhi', value: 'DELHI' },
  { key: 'Deoria', value: 'DEORIA' },
  { key: 'Durgapur', value: 'DURGAPUR' },
  { key: 'Davangere', value: 'DAVANGERE' },
  { key: 'Eluru', value: 'ELURU' },
  { key: 'Erode', value: 'ERODE' },
  { key: 'Faridabad', value: 'FARIDABAD' },
  { key: 'Ferozepur', value: 'FEROZEPUR' },
  { key: 'Gandhinagar', value: 'GANDHINAGAR' },
  { key: 'Gurgaon', value: 'GURGAON' },
  { key: 'Gulbarga', value: 'GULBARGA' },
  { key: 'Gandhidham', value: 'GANDHIDHAM' },
  { key: 'Gwalior', value: 'GWALIOR' },
  { key: 'Gonda', value: 'GONDA' },
  { key: 'Kfintech Panjim', value: 'KFINTECH PANJIM' },
  { key: 'Ghazipur', value: 'GHAZIPUR' },
  { key: 'Gorakhpur', value: 'GORAKHPUR' },
  { key: 'Guntur', value: 'GUNTUR' },
  { key: 'Guwahati', value: 'GUWAHATI' },
  { key: 'Gaya', value: 'GAYA' },
  { key: 'Ghaziabad', value: 'GHAZIABAD' },
  { key: 'Haridwar', value: 'HARIDWAR' },
  { key: 'Haldwani', value: 'HALDWANI' },
  { key: 'Hissar', value: 'HISSAR' },
  { key: 'Hoshiarpur', value: 'HOSHIARPUR' },
  { key: 'Hassan', value: 'HASSAN' },
  { key: 'Hubli', value: 'HUBLI' },
  { key: 'Hyderabad', value: 'HYDERABAD' },
  { key: 'Indore', value: 'INDORE' },
  { key: 'Jaipur', value: 'JAIPUR' },
  { key: 'Jabalpur', value: 'JABALPUR' },
  { key: 'Junagadh', value: 'JUNAGADH' },
  { key: 'Jalgaon', value: 'JALGAON' },
  { key: 'Jhansi', value: 'JHANSI' },
  { key: 'Jammu', value: 'JAMMU' },
  { key: 'Jalandhar', value: 'JALANDHAR' },
  { key: 'Jamshedpur', value: 'JAMSHEDPUR' },
  { key: 'Jamnagar', value: 'JAMNAGAR' },
  { key: 'Jodhpur', value: 'JODHPUR' },
  { key: 'Jalpaiguri', value: 'JALPAIGURI' },
  { key: 'Jaunpur', value: 'JAUNPUR' },
  { key: 'Karimnagar', value: 'KARIMNAGAR' },
  { key: 'Karnal', value: 'KARNAL' },
  { key: 'Karaikudi', value: 'KARAIKUDI' },
  { key: 'Karur', value: 'KARUR' },
  { key: 'Kolhapur', value: 'KOLHAPUR' },
  { key: 'Kollam', value: 'KOLLAM' },
  { key: 'Kanpur', value: 'KANPUR' },
  { key: 'Korba', value: 'KORBA' },
  { key: 'Kota', value: 'KOTA' },
  { key: 'Kharagpur', value: 'KHARAGPUR' },
  { key: 'Kurnool', value: 'KURNOOL' },
  { key: 'Kannur', value: 'KANNUR' },
  { key: 'Kottayam', value: 'KOTTAYAM' },
  { key: 'Lucknow', value: 'LUCKNOW' },
  { key: 'Ludhiana', value: 'LUDHIANA' },
  { key: 'Mathura', value: 'MATHURA' },
  { key: 'Chennai', value: 'CHENNAI' },
  { key: 'Mandi', value: 'MANDI' },
  { key: 'Meerut', value: 'MEERUT' },
  { key: 'Mehsana', value: 'MEHSANA' },
  { key: 'Mirzapur', value: 'MIRZAPUR' },
  { key: 'Malda', value: 'MALDA' },
  { key: 'Mangalore', value: 'MANGALORE' },
  { key: 'Moga', value: 'MOGA' },
  { key: 'Morena', value: 'MORENA' },
  { key: 'Moradabad', value: 'MORADABAD' },
  { key: 'Malappuram', value: 'MALAPPURAM' },
  { key: 'Margoa', value: 'MARGOA' },
  { key: 'Madurai', value: 'MADURAI' },
  { key: 'Mysore', value: 'MYSORE' },
  { key: 'Muzaffarpur', value: 'MUZAFFARPUR' },
  { key: 'Nashik', value: 'NASHIK' },
  { key: 'Nadiad', value: 'NADIAD' },
  { key: 'Nagpur', value: 'NAGPUR' },
  { key: 'Nizamabad', value: 'NIZAMABAD' },
  { key: 'Nagarkoil', value: 'NAGARKOIL' },
  { key: 'Namakkal', value: 'NAMAKKAL' },
  { key: 'Nanded', value: 'NANDED' },
  { key: 'Noida', value: 'NOIDA' },
  { key: 'Nellore', value: 'NELLORE' },
  { key: 'Navsari', value: 'NAVSARI' },
  { key: 'Patna', value: 'PATNA' },
  { key: 'Pollachi', value: 'POLLACHI' },
  { key: 'Pondicherry', value: 'PONDICHERRY' },
  { key: 'Palghat', value: 'PALGHAT' },
  { key: 'Pathankot', value: 'PATHANKOT' },
  { key: 'Pudukottai', value: 'PUDUKOTTAI' },
  { key: 'Panipat', value: 'PANIPAT' },
  { key: 'Proddatur', value: 'PRODDATUR' },
  { key: 'Patiala', value: 'PATIALA' },
  { key: 'Pune', value: 'PUNE' },
  { key: 'Ratlam', value: 'RATLAM' },
  { key: 'Raipur', value: 'RAIPUR' },
  { key: 'Rajahmundry', value: 'RAJAHMUNDRY' },
  { key: 'Rajkot', value: 'RAJKOT' },
  { key: 'Rourkela', value: 'ROURKELA' },
  { key: 'Ranchi', value: 'RANCHI' },
  { key: 'Roorkee', value: 'ROORKEE' },
  { key: 'Rohtak', value: 'ROHTAK' },
  { key: 'Rajapalayam', value: 'RAJAPALAYAM' },
  { key: 'Renukoot', value: 'RENUKOOT' },
  { key: 'Rewa', value: 'REWA' },
  { key: 'Salem', value: 'SALEM' },
  { key: 'Sagar', value: 'SAGAR' },
  { key: 'Sambalpur', value: 'SAMBALPUR' },
  { key: 'Sri Ganganagar', value: 'SRI GANGANAGAR' },
  { key: 'Shillong', value: 'SHILLONG' },
  { key: 'Shimla', value: 'SHIMLA' },
  { key: 'Sikar', value: 'SIKAR' },
  { key: 'Siliguri', value: 'SILIGURI' },
  { key: 'Shaktinagar', value: 'SHAKTINAGAR' },
  { key: 'Sivakasi', value: 'SIVAKASI' },
  { key: 'Silchar', value: 'SILCHAR' },
  { key: 'Solapur', value: 'SOLAPUR' },
  { key: 'Shimoga', value: 'SHIMOGA' },
  { key: 'Sonepat', value: 'SONEPAT' },
  { key: 'Solan', value: 'SOLAN' },
  { key: 'Sitapur', value: 'SITAPUR' },
  { key: 'Srikakulam', value: 'SRIKAKULAM' },
  { key: 'Saharanpur', value: 'SAHARANPUR' },
  { key: 'Satna', value: 'SATNA' },
  { key: 'Surat', value: 'SURAT' },
  { key: 'Sultanpur', value: 'SULTANPUR' },
  { key: 'Shivpuri', value: 'SHIVPURI' },
  { key: 'Tuticorin', value: 'TUTICORIN' },
  { key: 'Thiruvananthapuram', value: 'THIRUVANANTHAPURAM' },
  { key: 'Tiruvalla', value: 'TIRUVALLA' },
  { key: 'Thanjavur', value: 'THANJAVUR' },
  { key: 'Thodupuzha', value: 'THODUPUZHA' },
  { key: 'Tirupati', value: 'TIRUPATI' },
  { key: 'Trichy', value: 'TRICHY' },
  { key: 'Tirupur', value: 'TIRUPUR' },
  { key: 'Trichur', value: 'TRICHUR' },
  { key: 'Tirunelveli', value: 'TIRUNELVELI' },
  { key: 'Udaipur', value: 'UDAIPUR' },
  { key: 'Ujjain', value: 'UJJAIN' },
  { key: 'Varanasi', value: 'VARANASI' },
  { key: 'Valsad', value: 'VALSAD' },
  { key: 'Vijayanagaram', value: 'VIJAYANAGARAM' },
  { key: 'Vijayawada', value: 'VIJAYAWADA' },
  { key: 'Vellore', value: 'VELLORE' },
  { key: 'Vapi', value: 'VAPI' },
  { key: 'Visakhapatnam', value: 'VISAKHAPATNAM' },
  { key: 'Warangal', value: 'WARANGAL' },
  { key: 'Yamuna Nagar', value: 'YAMUNA NAGAR' },
];

export const branchMaster = amcRmBranchMaster
  .sort((city1, city2) =>
    (city1['value'] || '').toString().localeCompare((city2['value'] || '').toString())
  )
  .map((value) => ({
    key: value.value,
    value: value.key,
  }));

export const DistributorChannelMaster = [
  'Direct',
  'Wealth',
  'Branch',
  'PCG & HNI',
  'Omni',
  'Franchisee',
  'IFA',
  'EBG',
  'EWG',
  'Retail Branch',
  'Private Wealth',
  'Premia',
  'Banca',
  'Alternates',
  'La Premia',
  'ANG (Associate Group Network)',
  'PCG',
  'PCG Dealing Network',
  'Branch Dealing',
  'Dealing',
  'Retail',
  'NRI',
  'Burgandy',
  'Burgandy Private',
  'GPC',
  'Private Banking',
  'Bank',
  'Private',
  'Branch Banking',
  'Pioneer',
  'Privy',
  'Dubai',
  'MUBB',
  'Affluent',
]
  .sort((city1, city2) => city1.toString().localeCompare(city2.toString()))
  .map((value) => ({
    key: value,
    value: value,
  }));
