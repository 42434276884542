import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import {
  Formik,
  setNestedObjectValues,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
  // useFormikContext,
} from 'formik';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { Applicant, Bank } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  genderMasters,
  nationalitiesMasters,
  salutationsMasters,
  USER_ROLES,
} from '../../utils/constant';
import {
  getApplicantName,
  getApplicantType,
  getFirstHolderName,
  minDateForContributor,
  saveForLater,
  getRelation,
  applyRoleBasedStatus,
  applicationComparison,
  sendApplication_Nri,
  removeSingleQuote,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { contributorDetailsSchema } from '../../utils/schema';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Relations } from '../../utils/constant';
import { getNationalityList } from '../../redux-store/actions';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { useSnackbar } from 'notistack';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { showError } from '../../redux-store/actions/auth';

const contributorObject: Partial<Applicant> = {
  name: '',
  namePrefix: '',
  motherName: '',
  fatherOrSpouseName: '',
  dateOfBirth: minDateForContributor().toString(),
  status: '',
  nationality: 'INDIAN',
  panNo: '',
  relationShipWithFirstApplicant: '',
  mobile: '',
  gender: '',
};
type updateApplicant = Applicant & {
  jointApplicantRelation: string | null;
};
export type Values = {
  modeOfHolding: string;
  applicants: Partial<updateApplicant>[];
  banks: Bank[];
  // poaHolderName: string;
  saveType: string;
  nationalityDropdown: string[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [contributorObject],
  // poaHolderName: '',
  saveType: 'save and proceed',
  banks: [],
  nationalityDropdown: [],
};

const HolderDetails = ({
  index,
  values,
  nationalitiesMdmsResponse,
  applicationStatus,
  dataFetchedFromKRA,
}: {
  index: number;
  values: Values;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  applicationStatus?: string;
  dataFetchedFromKRA: boolean | null;
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  const [open, setOpen] = useState<{
    index: number;
    status: string;
  } | null>(null);

  const handleOpen = (index: number, status: string) => {
    setOpen({ index, status });
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.name`}
          label="Name *"
          placeholder="Enter Name"
          onChange={(e) =>
            setFieldValue(`applicants.${index}.name`, e.target.value.replace(/\s+/g, ' '))
          }
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.namePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                disabled={isFieldDisabled}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
          // onChange={({ target: { value } }) => {
          //   setFieldValue(`applicants.${index}.name`, value);
          //   index === 0 &&
          //     setFieldValue(
          //       'banks',
          //       values.banks.length
          //         ? values.banks.map((bank) => ({
          //             ...bank,
          //             pennydropCheck: false,
          //             pennydropVerified: false,
          //           }))
          //         : []
          //     );
          // }}
          // disabled={isFieldDisabled || dataFetchedFromKRA || false}
          disabled={isFieldDisabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.gender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
          disabled={dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.motherName`}
          label="Mother Name"
          placeholder="Enter Mother Name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.fatherOrSpouseName`}
          label="Father/Spouse Name *"
          placeholder="Enter Father/Spouse Name"
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <MFTextField
          name="date-of-birth"
          label="Date of Birth *"
          placeholder="DD/MM/YYYY"
          startAdornment={
            <InputAdornment position="start">
              <CardMedia component="img" alt="Calender" src="images/calender.svg" />
            </InputAdornment>
          }
        />
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <DatePicker
          label={'Date of birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of birth'}
          maxDate={minDateForContributor()}
          name={`applicants.${index}.dateOfBirth`}
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.status`}
          label="Status *"
          items={Object.keys(applicantStatusMasters).map((status) => ({
            key: applicantStatusMasters[status],
            value: status,
          }))}
          disabled={
            isFieldDisabled ||
            APPLICATION_LISTING_STATUS[applicationStatus || ''] !== APPLICATION_LISTING_STATUS.draft
          }
          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
            if (applicantStatusMasters[value as string] === applicantStatusMasters.NRI) {
              if (
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                APPLICATION_LISTING_STATUS.sent_to_applicant1
              ) {
                handleOpen(index, value as string);
              } else {
                setFieldValue(`applicants.${index}.status`, value);
                setFieldValue(`applicants.${index}.nationality`, 'INDIAN');
              }
            } else {
              if (
                !values.applicants
                  .map((applicant, ind) => {
                    if (ind === index) {
                      return value;
                    }
                    return applicant.status;
                  })
                  .includes('NRI') &&
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                  APPLICATION_LISTING_STATUS.sent_to_amc_approver
              ) {
                return handleOpen(index, value as string);
              }

              setFieldValue(`applicants.${index}.status`, value);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SearchableSelect
          name={`applicants.${index}.nationality`}
          label="Country/ Nationality of Applicant *"
          items={nationalitiesMdmsResponse.map((nationality) => ({
            key: nationality.nationality,
            value: nationality.nationality,
          }))}
          disabled={
            isFieldDisabled
            // ||
            // applicantStatusMasters[values?.applicants[index]?.status as string] ===
            //   applicantStatusMasters.NRI
          }
          searchFieldPlaceholder={'Search Nationality'}
        />
        {/* <UseRadioGroup
          name={`applicants.${index}.nationality`}
          formLabel="Nationality of Applicant *"
          defaultValue="Indian"
          items={nationalitiesMasters
            .filter((_nationality) => {
              return values.applicants[index].status === 'NRI'
                ? _nationality !== 'Indian'
                : _nationality;
            })
            .map((_nationality) => ({
              label: _nationality,
              value: _nationality,
            }))}
          disabled={isFieldDisabled}
        /> */}
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.panNo`}
          label="PAN *"
          placeholder="Enter PAN"
          disabled={true}
        />
      </Grid>
      {index !== 0 && (
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.jointApplicantRelation`}
            label="Relationship With First Applicant *"
            items={Relations.filter((relation) => {
              const { applicants } = values;
              const selectedRelations = applicants
                .filter(
                  (_applicant, ind) =>
                    ind !== index &&
                    _applicant.jointApplicantRelation &&
                    ['Mother', 'Father', 'Spouse'].includes(_applicant.jointApplicantRelation)
                )
                .map((applicant) => applicant.jointApplicantRelation);
              return !selectedRelations.includes(relation);
            }).map((relation) => ({
              key: relation,
              value: relation,
            }))}
          />
          {values.applicants.map((applicant, idx: number) => {
            if (applicant.jointApplicantRelation === 'Others' && idx === index) {
              return (
                <MFTextField
                  name={`applicants.${index}.relationShipWithFirstApplicant`}
                  label="Please Specify Relationship *"
                  placeholder={`Enter Relationship`}
                />
              );
            }
          })}
        </Grid>
      )}
      <ConfirmationDialog
        message={`Any change in the investor status will affect the application's status and workflow. Are you sure you want to proceed?`}
        open={open !== null}
        setOpen={handleClose}
        onSave={() => {
          setFieldValue(`applicants.${open?.index}.status`, open?.status);
          setFieldValue(`applicants.${open?.index}.nationality`, 'INDIAN');
          handleClose();
        }}
        onCancel={handleClose}
      />
      {/* <InfoDialog onClose={handleClose} open={open} content={'status changed to draft.'} /> */}
    </>
  );
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [contributorValues, setContributorValues] = useState(initialValues);
  const [applicantToDelete, setApplicantToDelete] = useState<{
    index: number;
  } | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   (async function () {
  //     try {
  //       const nationalitiesMdmsMasters = (await dispatch(
  //         getNationalityList()
  //       )) as unknown as nationaliyType;
  //       setMdmsCountriesList(nationalitiesMdmsMasters.countries);
  //     } catch (e) {
  //       console.error((e as Error).message);
  //     }
  //   })();
  // }, [application]);

  useEffect(() => {
    (async function () {
      try {
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        const { applicants = [], modeOfHolding = '', banks = [] } = application || {};
        const sortedApplicants = applicants
          .map((applicant) => ({
            ...applicant,
            applicant_type: getApplicantType(applicant.applicant_type),
          }))
          .sort((applicant1, applicant2) => {
            return Number(applicant1.applicant_type) - Number(applicant2.applicant_type);
          });
        setContributorValues({
          ...contributorValues,
          banks,
          modeOfHolding:
            modeOfHolding === 'joint'
              ? 'joint'
              : sortedApplicants.length > 1
              ? 'either or survivor'
              : modeOfHolding,
          applicants: applicants.length
            ? sortedApplicants.map((applicant) => ({
                ...applicant,
                nationality: applicant.nationality
                  ? applicant.nationality.toUpperCase()
                  : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
                  ? ''
                  : 'INDIAN',
                namePrefix:
                  applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
                    ? applicant.namePrefix
                    : 'Mr.',
                jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
                  ? applicant.relationShipWithFirstApplicant
                  : 'Others',
                relationShipWithFirstApplicant: getRelation(
                  applicant.relationShipWithFirstApplicant
                )
                  ? ''
                  : applicant.relationShipWithFirstApplicant,
              }))
            : [contributorObject],
          nationalityDropdown: nationalitiesMdmsMasters.countries.map((list) => list.nationality),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  // const validate = (values: Values): FormikErrors<Values> => {
  //   let errors: FormikErrors<Values> = {};
  //   const { applicants, poaHolderName } = values;
  //   if (applicants.length > 1 && !poaHolderName) {
  //     errors = { ...errors, poaHolderName: 'Please select POA' };
  //   }
  //   return errors;
  // };

  const handleApplicant = async (values: Values) => {
    const { applicants = [], modeOfHolding = '', saveType, banks = [] } = values;
    const {
      id,
      currentStep,
      hasPOA,
      status,
      applicant1ReferenceId = '',
      applicationNumber,
    } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    const updatedBanks =
      applicants[0].name?.split("'").join('').trim() ===
      application?.applicants[0].name?.split("'").join('').trim()
        ? banks
        : banks.map((bank) => ({
            ...bank,
            pennydropCheck: false,
            pennydropVerified: false,
          }));
    const updatedApplicants = values.applicants.map((applicant) => {
      const { jointApplicantRelation, ...rest } = applicant;
      return {
        ...rest,
        // status,
        // addresses: addresses.length
        //   ? addresses.map((address) => {
        //       if (
        //         address.address_type === 'overseas' &&
        //         nationality.toLowerCase() === 'indian' &&
        //         applicantStatusMasters[status as string] === applicantStatusMasters.Individual
        //       ) {
        //         return { ...address, isActive: false };
        //       }
        //       return address;
        //     })
        //   : [],
        name: removeSingleQuote(applicant.name),
        fatherOrSpouseName: removeSingleQuote(applicant.fatherOrSpouseName),
        motherName: removeSingleQuote(applicant.motherName),
        relationShipWithFirstApplicant: getRelation(jointApplicantRelation)
          ? jointApplicantRelation
          : removeSingleQuote(applicant.relationShipWithFirstApplicant),
      };
    });
    const updatedModeOfHolding =
      applicants.filter((applicant) => applicant.isActive).length === 1 ? 'single' : modeOfHolding;
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks: updatedBanks,
        applicants: updatedApplicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
        modeOfHolding: updatedModeOfHolding,
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );
    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...application!,
            banks: updatedBanks,
            applicants: updatedApplicants,
            modeOfHolding: updatedModeOfHolding,
            ...(saveType === 'save and proceed' && {
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : (sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                      APPLICATION_LISTING_STATUS[status as string] ===
                        APPLICATION_LISTING_STATUS.sent_to_applicant1) ||
                    (!sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                      APPLICATION_LISTING_STATUS[status as string] ===
                        APPLICATION_LISTING_STATUS.sent_to_amc_approver)
                  ? 'draft'
                  : status,
            }),
            currentStep: 2,
            // !!currentStep && currentStep > 1
            //   ? applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //     isAllBanksPennyChecked
            //     ? 2
            //     : currentStep
            //   : applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //     isAllBanksPennyChecked
            //   ? 2
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
      saveType === 'save and proceed'
        ? history.push('contact-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      saveType === 'save and proceed'
        ? history.push('contact-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      await handleApplicant(values);
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={contributorValues}
      onSubmit={handleSubmit}
      //validationSchema={contributorDetailsSchema}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, contributorDetailsSchema, true, values);
        } catch (e) {
          console.log(e);
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({
        handleSubmit,
        values,
        setValues,
        validateForm,
        setErrors,
        setTouched,
        setFieldValue,
      }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Notes displayContent={'The Name should be match with the Name in Aadhaar Card'} />
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Contributor details of {getApplicantName(index + 1)} Applicant
                  {index !== 0 && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => setApplicantToDelete({ index })}>
                      <Typography
                        sx={{
                          color: 'rgba(196, 42, 51, 0.8)',
                          fontSize: 12,
                          ml: 'auto',
                          letterSpacing: '-0.2px',
                          cursor: 'pointer',
                        }}>
                        Remove Joint Holder{' '}
                      </Typography>
                      <IconButton sx={{ p: '2px' }}>
                        <CardMedia
                          component="img"
                          src="/images/delete-red.svg"
                          alt="Delete Icon"
                          sx={{ width: 'unset' }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </SubHeading>
                <HolderDetails
                  index={index}
                  values={values}
                  nationalitiesMdmsResponse={mdmsCountriesList}
                  applicationStatus={application?.status}
                  dataFetchedFromKRA={applicant.dataFetchedFromKRA || null}
                />
              </React.Fragment>
            ))}
            {!!values.applicants.length &&
              values.applicants.length < 3 &&
              ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{
                      color: 'primary.main',
                      fontWeight: 500,
                      fontSize: 14,
                      mt: 2,
                    }}
                    onClick={async () => {
                      const formErrors = await validateForm();
                      if (!Object.keys(formErrors).length) {
                        await handleApplicant(values);
                        history.push('/onboarding/enter-pan-number', {
                          redirectTo: '/application/contributor-details',
                          application,
                          firstHolderName: getFirstHolderName(values.applicants),
                        });
                        return;
                      }
                      setTouched(setNestedObjectValues(formErrors, true));
                      setErrors(formErrors);
                    }}>
                    Add Joint Holder
                  </Button>
                </Grid>
              )}
            {values.applicants.length > 1 && (
              <>
                <SubHeading>Redeem </SubHeading>
                <Grid item xs={12}>
                  <MFCheckbox
                    checked={values.modeOfHolding === 'either or survivor'}
                    name="holding"
                    label="Either or Survivor"
                    onChange={({ target: { checked } }) => {
                      setFieldValue('modeOfHolding', checked ? 'either or survivor' : 'joint');
                      // setOpen(checked ? false : true);
                    }}
                    disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
                  />
                </Grid>
                {/* {values.modeOfHolding !== 'joint' && (
                  <>
                    <SubHeading>POA</SubHeading>
                    <Grid item xs={12}>
                      <UseRadioGroup
                        formLabel=""
                        name="poaHolderName"
                        items={values.applicants.map((applicant, index) => ({
                          label: applicant.name || `Applicant ${index + 1}`,
                          value: applicant.name || `Applicant ${index + 1}`,
                        }))}
                      />
                    </Grid>
                  </>
                )} */}
              </>
            )}
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                p: 4,
                width: { xs: '70%', sm: '70%' },
              },
            }}>
            <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box sx={{ mb: 2 }}>
              <CardMedia
                component="img"
                src="/images/warning-icon-red.svg"
                sx={{ width: 'unset', mx: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto',
                '.MuiTypography-root': {
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}>
              <Typography sx={{ color: '#FD3A69', lineHeight: '35px' }}>
                Disabling “Either or Survivor” will cause issues later.
              </Typography>
              <Typography sx={{ color: 'primary.main', lineHeight: '35px' }}>
                Are you willing to disable?
              </Typography>
            </Box>
            <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'joint',
                  });
                  handleClose();
                }}
                variant="outlined"
                sx={{
                  fontSize: 14,
                  color: 'primary.main',
                  minWidth: 200,
                }}>
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'either or survivor',
                  });
                  handleClose();
                }}
                sx={{
                  fontSize: 14,
                  minWidth: 200,
                }}>
                No
              </Button>
            </Grid>
          </Dialog>
          <ConfirmationDialog
            message={`Are you sure you want to delete applicant ?`}
            open={applicantToDelete !== null}
            setOpen={() => setApplicantToDelete(null)}
            onSave={async () => {
              try {
                const remainedApplicants = values.applicants.map((applicant, i) => {
                  const noOfApplicants = values.applicants.length;
                  if (i === applicantToDelete?.index) {
                    return {
                      ...applicant,
                      isActive: false,
                    };
                  }
                  if (noOfApplicants === 3 && i === 2) {
                    return {
                      ...applicant,
                      applicant_type: '2',
                    };
                  }
                  return applicant;
                });
                const defaultPayload = {
                  applicants: remainedApplicants.map((applicant) => {
                    const { jointApplicantRelation, ...rest } = applicant;
                    return {
                      ...rest,
                      relationShipWithFirstApplicant: getRelation(jointApplicantRelation)
                        ? jointApplicantRelation
                        : applicant.relationShipWithFirstApplicant,
                    };
                  }),
                  modeOfHolding:
                    remainedApplicants.filter((applicant) => applicant.isActive).length === 1
                      ? 'single'
                      : values.modeOfHolding,
                };
                if (application?.id) {
                  setApplicantToDelete(null);
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        ...defaultPayload,
                        currentStep: 1,
                        status:
                          !application?.hasPOA &&
                          AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                          application?.status !== 'draft' &&
                          applyRoleBasedStatus(role)
                            ? 'sent_to_amc_approver'
                            : application?.status,
                      },
                      applicationId: application.id,
                    })
                  );
                }
              } catch (error) {
                typeof error === 'string' && dispatch(showError(error));
                console.error((error as Error).message);
              }
            }}
            onCancel={() => setApplicantToDelete(null)}
          />
        </>
      )}
    </Formik>
  );
}
