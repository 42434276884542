import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import React from 'react';
import { Gridstyles } from './index';
import { NomineeType } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SubHeading } from '../investors/components';
import { isMinor } from '../../utils/utilityFunctions';

export default function NomineeDetails({
  nominees = [],
}: {
  nominees: Partial<NomineeType>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography
              sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', width: '100%' }}>
              Nominee Details
            </Typography>
            {nominees.length === 0 ? (
              <Gridstyles>
                <Grid item xs={12} display="flex" alignItems={'center'} my={2}>
                  <Checkbox defaultChecked disabled={true} />
                  <Typography
                    sx={{
                      fontSize: 16,
                      fontWeight: 700,
                      color: 'rgba(0, 0, 0, 0.6)',
                      letterSpacing: '0.5px',
                    }}>
                    I/We do not wish to nominate.
                  </Typography>
                </Grid>
              </Gridstyles>
            ) : (
              <>
                <Grid item xs={12} display="flex" alignItems={'flex-start'} my={2}>
                  <Checkbox defaultChecked disabled={true} />
                  <Typography
                    sx={{
                      fontSize: 15,
                      fontWeight: 700,
                      color: 'rgba(0, 0, 0, 0.6)',
                      letterSpacing: '0.5px',
                    }}>
                    I/We do hereby nominate the undermentioned Nominee to receive the Units allotted
                    to my/our credit in my/our folio in the event of my/our death. I/We also
                    understand that all payments and settlements made to such Nominee and Signature
                    of the Nominee acknowledging receipt thereof, shall be valid discharge by the
                    AMC/ Fund/ Trustees/ Motilal Oswal Alternative Investment Trust. In case of
                    units held in demat mode, the nomination under demat account will be considered.
                  </Typography>
                </Grid>

                {nominees.map((nominee, index: number) => {
                  const title = [
                    'First Nominee Details',
                    'Second Nominee Details',
                    'Third Nominee Details',
                  ][index];
                  return (
                    <React.Fragment key={nominee.id}>
                      <SubHeading sx={{ color: 'common.black', letterSpacing: 0.5 + 'px' }}>
                        {title}
                      </SubHeading>
                      <Gridstyles>
                        <Grid
                          item
                          container
                          pt={2}
                          pb={5}
                          rowSpacing={6}
                          columnSpacing={13}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start">
                          <KeyValue
                            title={`Nominee Name ${index + 1}`}
                            description={nominee.nomineeName}
                          />
                          <KeyValue
                            title={'Nominee RelationShip'}
                            description={nominee.nomineeRelationship}
                          />
                          <KeyValue
                            title={'Date Of Birth'}
                            description={formatShortDate(nominee.dateOfBirth)}
                          />
                          <KeyValue
                            title={'Nominee Percentage'}
                            description={nominee.nomineePercentage}
                          />
                          <KeyValue title={'Nominee PAN'} description={nominee.nomineePan} />
                          {nominee.dateOfBirth && isMinor(nominee.dateOfBirth) && (
                            <>
                              <KeyValue
                                title={'Guardian Name'}
                                description={nominee.guardianName}
                              />
                              <KeyValue title={'Guardian PAN'} description={nominee.guardianPan} />
                            </>
                          )}
                        </Grid>
                      </Gridstyles>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
