import { Box, Button, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles, Item, ItemDetails } from './index';
import { formatShortDate } from '../../utils/date';
import { currencyConversion } from '../../utils/utilityFunctions';
import { AdditionalReturn } from '../investors/components';
import ExitLoad from './exitLoad';
import DefaulterPenalty from './defaulterPenalty';

export default function InvestAndPaymentDetails({
  application,
}: {
  application: Partial<ApplicationProps>;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                {/* <KeyValue title={'Fund (Scheme)'} description={application.scheme?.schemeName} /> */}
                <Grid item xs={12} sm={6}>
                  <Item>{'Fund (Scheme)'}</Item>
                  <ItemDetails>
                    {' '}
                    {application.scheme?.schemeName ? application.scheme?.schemeName : '-'}
                  </ItemDetails>
                  {application.plan?.tcLink && (
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="h5"
                        sx={{
                          mt: 2,
                          color: '#61D1D6',
                          textDecoration: 'none',
                          fontWeight: 400,
                          fontSize: '15px',
                          cursor: 'pointer',
                          ':hover': {
                            color: 'primary.main',
                          },
                        }}
                        onClick={() => {
                          window.open(application.plan?.tcLink);
                        }}>
                        {`View PPM`}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <KeyValue title={'Class Plan'} description={application.plan?.planDescription} />
                <KeyValue
                  title={'Commitment Amount'}
                  description={application.commitmentAmount}
                  amount={currencyConversion(application.commitmentAmount)}
                />
                <KeyValue
                  title={'Management Fees Rate'}
                  description={
                    application.planId && Number(application.managementFeesRate) === 0
                      ? '0'
                      : application.managementFeesRate
                  }
                />
                {/* <KeyValue title={'Tax Rate'} description={application.taxRate} /> */}
                {/* <KeyValue title={'DrawDown Number'} description={application.drawDownNumber} /> */}
                <KeyValue
                  title={'Initial Contribution Amount'}
                  description={application.contributionChequeAmount}
                  amount={currencyConversion(application.contributionChequeAmount)}
                />
                <KeyValue
                  title={'Setup Fee Percentage'}
                  description={
                    application.planId && Number(application.setupFeePercentage) === 0
                      ? '0'
                      : application.setupFeePercentage
                  }
                />
                <KeyValue
                  title={'Setup Fee Amount'}
                  description={
                    application.planId && Number(application.setupFeeAmount) === 0
                      ? '0'
                      : application.setupFeeAmount
                  }
                  amount={currencyConversion(application.setupFeeAmount)}
                />
                <KeyValue
                  title={'GST (GST On Setup Fee Amount)'}
                  description={
                    application.planId && Number(application.setupFeeGST) === 0
                      ? '0'
                      : application.setupFeeGST
                  }
                  amount={currencyConversion(application.setupFeeGST)}
                />
                <KeyValue
                  title={'Total Setup Fee'}
                  description={
                    application.planId && Number(application.totalSetupFee) === 0
                      ? '0'
                      : application.totalSetupFee
                  }
                  amount={currencyConversion(application.totalSetupFee)}
                />
                {/* <KeyValue title={'TDS'} description={currencyConversion(application.setupFeeTDS)} />
                <KeyValue
                  title={'Setup Fee Payable'}
                  description={application.netSetupFeeLessTDS}
                  amount={currencyConversion(application.netSetupFeeLessTDS)}
                />
                <KeyValue
                  title={'Total Amount Payable'}
                  description={currencyConversion(application.totalAmountPayable)}
                /> */}
                <Grid item xs={12} sm={6}>
                  <Item>Category</Item>
                  <ItemDetails sx={{ textTransform: 'capitalize' }}>
                    {' '}
                    {application.category ? application.category : '-'}
                  </ItemDetails>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Item>Fee Type</Item>
                  <ItemDetails sx={{ textTransform: 'capitalize' }}>
                    {' '}
                    {application.feeType ? application.feeType : '-'}
                  </ItemDetails>
                </Grid>
                <Grid item xs={12} pb={2}>
                  <AdditionalReturn
                    additionalReturn={application.additionalReturnWithoutCatchup as string}
                    catchup={application.catchup as string}
                    hurdleRate={application.hurdleRateOfReturn as string}
                    planId={application.planId}
                  />
                </Grid>

                <Grid item xs={12} pb={2} mt={-6}>
                  <DefaulterPenalty defaulterPenalty={application?.scheme?.defaulterPenalty} />
                </Grid>
                <Grid item xs={12} pb={2} mt={-6}>
                  <ExitLoad exitLoad={application?.plan?.exitLoad} />
                </Grid>
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
        {/* <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Payment Details
            </Typography>
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue title={'Payment Bank Name'} description={application.paymentBankame} />
                <KeyValue
                  title={'Payment Bank Account Number'}
                  description={application.paymentBankAccountNumber}
                />
                <KeyValue
                  title={'Payment Bank Account Type'}
                  description={application.paymentBankAccontType}
                />
                <KeyValue title={'Mode Of Payment'} description={application.modeOfPayment} />
                <KeyValue
                  title={'Payment/Cheque Date'}
                  description={formatShortDate(application.chequeDate)}
                />
                <KeyValue title={'Payment Ref No'} description={application.paymentRefNo} />
              </Grid>
            </Gridstyles>
          </Grid>
        </Box> */}
      </Box>
    </>
  );
}
