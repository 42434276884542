import { Box, FormHelperText, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { maskMobileNumber } from '../../utils/utilityFunctions';
import { useDispatch } from 'react-redux';
import { getUserInfo, verifyLoginOtp } from '../../redux-store/actions/auth';
import { useHistory } from 'react-router';
import { LoginLayout, OtpTimer } from '../commonComponents';
import { getOtpForInvestor, verifyOtpForInvestor } from '../../redux-store/actions/onBoarding';
import { Location } from 'history';
import { loginWithOtp } from '../../redux-store/actions/auth';
import {
  GetOtpForInvestorResponse,
  LoginWithOtpResponseBody,
} from '../../redux-store/types/api-types';
import { Formik } from 'formik';
import { MFSubmitButton } from '../../lib/formik';

const intialValues: { OTP: string } = {
  OTP: '',
};

export default function LoginOtp({
  location,
}: {
  location: Location<{
    mobile: string;
    phone: string;
    email: string;
    verificationToken: string;
    loginType: string;
    referenceId: string;
    countryCode: string;
  }>;
}): JSX.Element {
  const {
    mobile = '',
    loginType = '',
    referenceId,
    countryCode = '+91',
    phone = '',
    email = '',
  } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState<string>('');
  const [{ verificationToken }, setVerifyOtpInfo] = useState({ verificationToken: '' });

  const handleSubmit = async (values: { OTP: string }) => {
    const { OTP } = values;
    try {
      if (!OTP || OTP.length !== 6) {
        setError('Enter OTP');
        return;
      }
      if (loginType === 'investor') {
        await dispatch(verifyOtpForInvestor(referenceId, { otp: OTP, verificationToken }));
        await dispatch(getUserInfo());
        history.push(`application-details`);
        return;
      }
      await dispatch(verifyLoginOtp({ phone: mobile, otp: OTP, verificationToken, countryCode }));
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    const { verificationToken } = location.state || {};
    if (!location.state) {
      history.push('/login');
    }
    setVerifyOtpInfo({ verificationToken });
  }, []);

  const onResend = async () => {
    try {
      if (loginType === 'investor') {
        const response = (await dispatch(
          getOtpForInvestor(referenceId)
        )) as unknown as GetOtpForInvestorResponse;
        setVerifyOtpInfo({ ...response });
        return;
      }
      const res = (await dispatch(
        loginWithOtp({ phone: mobile, countryCode })
      )) as unknown as LoginWithOtpResponseBody;
      setVerifyOtpInfo({ ...res });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <LoginLayout LoginText="Login with OTP">
      <Formik initialValues={intialValues} onSubmit={handleSubmit}>
        {({ values, setValues, handleSubmit }) => (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Typography sx={{ color: 'primary.main', textAlign: 'center', mb: 3.5 }}>
              An OTP has been sent to your mobile number
              <Typography component="span">{` ${phone} `}</Typography>
              {email && (
                <Typography component="span">
                  and email Id <Typography component="span"> {email}</Typography>
                </Typography>
              )}
            </Typography>
            <Box
              sx={{
                mb: 0,
                '.otp-input': {
                  input: {
                    width: { xs: '37px !important', md: '45px !important' },
                    fontSize: { xs: '20px !important', md: '30px !important' },
                    margin: { xs: '2px !important', md: '4px !important' },
                  },
                },
              }}>
              <OtpInput
                value={values.OTP}
                onChange={(OTP: string) => setValues({ OTP })}
                numInputs={6}
                hasErrored={!!error}
                isInputNum={true}
                isInputSecure={true}
                shouldAutoFocus={true}
                containerStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
                inputStyle={{
                  padding: '5px 10px',
                  // width: '45px',
                  borderRadius: '5px',
                  fontSize: '30px',
                }}
                errorStyle={{ border: '2px solid red', color: 'red' }}
                focusStyle={{ outline: 'none' }}
                className="otp-input"
              />
              {!!error && (
                <FormHelperText error sx={{ mt: 2, textAlign: 'center', fontSize: '14px' }}>
                  {error}
                </FormHelperText>
              )}
            </Box>
            <Box
              sx={{
                '.MuiTypography-root, .MuiButton-root': {
                  textAlign: 'center',
                },
              }}>
              <OtpTimer onResend={onResend} />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <MFSubmitButton label="Submit" />
            </Box>
          </Box>
        )}
      </Formik>
    </LoginLayout>
  );
}
