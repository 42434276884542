import { Typography } from '@mui/material';
import React from 'react';
import { Item } from '../Investments/investor-application';

export default function DefaulterPenalty({
  defaulterPenalty,
}: {
  defaulterPenalty: any;
}): JSX.Element {
  return !isNaN(Number(defaulterPenalty)) && defaulterPenalty ? (
    <>
      <Item
        sx={{
          fontSize: 16,
          fontWeight: 500,
          color: 'rgba(0,0,0,0.7)',
          mb: 1,
        }}>
        Defaulter penalty
      </Item>
      <Typography sx={{ color: 'text.secondary' }}>
        In case of delay in payment of commitment amount there is an applicable interest charge of
        1.5% + GST per month on the delayed amount.
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>
        In case of non-payment of the entire commitment amount there is an applicable exit penalty
        of{' '}
        <Typography
          sx={{ fontWeight: 700 }}
          component={'span'}>{`${defaulterPenalty}%`}</Typography>{' '}
        + GST calculated on commitment amount.
      </Typography>
    </>
  ) : (
    <></>
  );
}
