import { Box, Typography, Button, AppBar, CardMedia, Divider, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  ApplicationProps,
  Broker,
  BrokerList,
  Document,
  esignApplicationResponse,
} from '../../redux-store/types/api-types';
import {
  esignApplication,
  getApplicationDetailsWithRefId,
} from '../../redux-store/actions/onBoarding';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Footer } from '../commonComponents';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ESIGN_STATUS } from '../../utils/constant';
import {
  getBrokers,
  getDocuments,
  isFormValidForSubmission,
} from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { ApplicationDetailsCommonLayout } from '../ApplicationDetails/index';
import { getNationalityList } from '../../redux-store/actions';
import { nationaliyType } from '../../redux-store/types/mdms';

export const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  paddingTop: '10px',
  marginLeft: '2px',
  color: theme.palette.text.secondary,
  fontSize: '15px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));
export const ItemDetails = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  fontSize: '16px',
  marginLeft: '2px',
  fontWeight: 700,
  letterSpacing: '0.5px',
  maxWidth: '100%',
  overflowWrap: 'anywhere',
}));
export const Gridstyles = styled('div')(({ theme }) => ({
  paddingLeft: '10%',
  [theme.breakpoints.only('xs')]: {
    paddingLeft: 0,
  },
}));

export default function InvestorApplication(): JSX.Element {
  const { referenceId } = useParams<{ referenceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { investor, auth } = useSelector((store: RootStateType) => store);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState<ApplicationProps>();
  const applicationDetailRef = useRef<HTMLDivElement>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const _application = (await dispatch(
          getApplicationDetailsWithRefId(referenceId)
        )) as unknown as ApplicationProps;
        setApplication(_application);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
    setLoading(true);
  }, []);

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(`/investment-details/${referenceId}/details`);
    }
  }, []);

  const handleEsign = async () => {
    try {
      const nationalitiesMdmsMasters = (await dispatch(
        getNationalityList()
      )) as unknown as nationaliyType;
      const documentsResponse = (await dispatch(getDocuments())) as unknown as Document;
      const brokersMasters = (await dispatch(getBrokers())) as unknown as BrokerList;
      if (ESIGN_STATUS.PENDING_SIGNATURE !== application?.signDetails.status) {
        ESIGN_STATUS.NOT_GENERATED === application?.signDetails.status
          ? await isFormValidForSubmission(
              application as ApplicationProps,
              true,
              true,
              nationalitiesMdmsMasters,
              brokersMasters.broker_list as Broker[],
              documentsResponse,
              auth.role
            )
          : await isFormValidForSubmission(
              application as ApplicationProps,
              true,
              false,
              nationalitiesMdmsMasters,
              brokersMasters.broker_list as Broker[],
              documentsResponse,
              auth.role
            );
      }
      setIsSubmitting(true);
      const { signDetails } = application || {};
      if (signDetails?.url) {
        window.open(signDetails?.url, '_blank');
        return;
      }
      const response = (await dispatch(
        esignApplication(referenceId)
      )) as unknown as esignApplicationResponse;
      setApplication({ ...application, signDetails: response } as unknown as ApplicationProps);
      window.open(response?.url, '_blank');
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e as string));
      console.error((e as Error).message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <IconButton
        sx={{
          position: 'fixed',
          right: 0,
          bottom: 75,
          borderRadius: '5px  0 0 5px',
          '&,:hover': {
            bgcolor: 'primary.main',
          },
        }}
        onClick={() => applicationDetailRef.current?.scrollIntoView({ behavior: 'smooth' })}>
        <ArrowUpwardIcon fontSize="large" sx={{ color: 'common.white' }} />
      </IconButton>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/motilal-logo.png"
            alt="Logo"
            sx={{ width: '120px', m: 3, my: 2.85 }}
          />
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }} ref={applicationDetailRef}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            mb: 2,
            justifyContent: 'space-between',
            mt: 10,
          }}>
          <Typography sx={{ fontSize: 20, fontWeight: 600, color: 'primary.main', mb: 4 }}>
            Application Details
          </Typography>
          {application?.editable && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push(`/investment-details/edit-application`, {
                    id: application?.id,
                    applicant1ReferenceId: application?.applicant1ReferenceId,
                  })
                }
              />
            </IconButton>
          )}
        </Box>
        {application && (
          <>
            <ApplicationDetailsCommonLayout
              loading={loading}
              application={application as ApplicationProps}
            />
            {![ESIGN_STATUS.SIGNED, ESIGN_STATUS.EXPIRED].includes(
              application.signDetails.status
            ) && (
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  sx={{
                    color: 'common.white',
                    minWidth: '200px',
                    mt: 4,
                    fontWeight: 600,
                    lineHeight: 1.5,
                  }}
                  onClick={handleEsign}
                  disabled={isSubmitting}>
                  {`e-Sign ${isSubmitting ? '...' : ''}`}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
      <Footer />
    </Box>
  );
}
