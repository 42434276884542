import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';
import { amcRmBranchMaster } from '../../utils/locationMasters';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                RM/Distributor Details
              </Typography>
            </Grid>

            <Gridstyles>
              <Grid
                item
                container
                pt={1}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={'Distributor ARN Code'}
                  description={application?.distributor?.arnCode}
                />
                <KeyValue title={'Distributor Name'} description={application?.distributor?.name} />
                <KeyValue
                  title={'Distributor Email Id'}
                  description={application?.distributor?.user?.email}
                />
                <KeyValue
                  title={'Distributor Phone Number'}
                  description={application?.distributor?.user?.phone}
                />
                <KeyValue
                  title={'Distributor RM Name'}
                  description={application?.subDistributor?.user?.firstName}
                />
                <KeyValue
                  title={'Distributor RM Email Id'}
                  description={application?.subDistributor?.user?.email}
                />
                <KeyValue
                  title={'Distributor RM Phone Number'}
                  description={application?.subDistributor?.user?.phone}
                />
                <KeyValue title={`AMC's RM Name`} description={application?.rm?.user?.firstName} />
                <KeyValue title={`AMC's RM Email Id`} description={application?.rm?.user?.email} />
                <KeyValue
                  title={`AMC's RM Phone Number`}
                  description={application?.rm?.user?.phone}
                />
                <KeyValue
                  title={'Support RM Name'}
                  description={application?.supportRm?.user?.firstName}
                />
                <KeyValue
                  title={'Support RM Email Id'}
                  description={application?.supportRm?.user?.email}
                />
                <KeyValue
                  title={'Support RM Phone Number'}
                  description={application?.supportRm?.user?.phone}
                />
                <KeyValue
                  title={'Sub Distributor Code'}
                  description={
                    application?.distributor?.distributorEmpCode ||
                    application?.subDistributor?.subDistributorCode
                  }
                />
                <KeyValue
                  title={'EUIN'}
                  description={application?.distributor?.euin || application?.subDistributor?.euin}
                />
                {!application.distributor_id && (
                  <KeyValue title={'ARN Code'} description={application?.rm?.empCode} />
                )}
                <KeyValue
                  title={'Distributor Channel'}
                  description={application.distributorChannel}
                />
                <KeyValue
                  title={'AMC Branch'}
                  description={
                    amcRmBranchMaster.find((branch) => branch.key === application?.rmBranch)
                      ?.value || application?.rmBranch
                  }
                />
                <KeyValue
                  title={'Distributor Location'}
                  description={application?.distributorBranch}
                />
                {/* {application?.subdistributorBranch && (
                  <KeyValue
                    title={'Subdistributor Branch'}
                    description={application?.subdistributorBranch}
                  />
                )} */}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
