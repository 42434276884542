import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  LinearProgress,
  SelectChangeEvent,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker } from '../../lib/formik/DatePicker';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import MFSelectField from '../../lib/formik/SelectField';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import { DistributorListType, FundManager, Trustee } from '../../redux-store/types/api-types';
import {
  addFund,
  addPlan,
  fundAccess,
  getPlanList,
  updateFund,
} from '../../redux-store/actions/funds';
import { Location } from 'history';
import {
  accessType,
  addFundPlanType,
  ClassPlanProps,
  FundProps,
} from '../../redux-store/types/funds';
import { addFundValidation, editFundValidation } from '../../utils/schema';
import { allowOnlyNumbers, currencyConversion } from '../../utils/utilityFunctions';
import UseRadioGroup from '../../lib/formik/Radio';
import {
  additionalReturnWithoutCatchupMaster,
  CatchupMaster,
  PlansCategoryMasters,
  PlansFeeTypeMaster,
  PlansSetupFeeTypeMasters,
  SchemeNatureMaster,
} from '../../utils/constant';
import { getCategoryMaster } from './addPlan';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import MFCheckbox from '../../lib/formik/Checkbox';

type NewPlanType = addFundPlanType & {
  addReturn: string;
  hurdleRate: string;
  isApplicableForDistributor: boolean;
};

const initialValues: NewPlanType = {
  schemeCode: '',
  schemeName: '',
  amcCode: '',
  fundTenure: 0,
  feeType: '',
  category: '',
  catchup: '',
  additionalReturnWithoutCatchup: '',
  hurdleRateOfReturn: '',
  bankName: '',
  bankBranch: '',
  ifscCode: '',
  bankAccountNumber: '',
  id: '',
  schemeId: null,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: '',
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  inActive: true,
  faceValue: 0,
  fundAddress1: '',
  fundAddress2: '',
  fundAddress3: '',
  isActive: true,
  custodian: '',
  setupFee: 0,
  fundManagerId: null,
  trusteeId: null,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: null,
  managementFee: 0,
  tcLink: '',
  addReturn: '',
  hurdleRate: '',
  distributorIds: null,
  isApplicableForDirect: false,
  isApplicableForDistributor: false,
  defaulterPenalty: 'NA',
  exitLoad: '',
};

export default function AddFund({
  location,
}: {
  location: Location<{ fundId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<FundManager[]>([]);
  const [editField, setEditField] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { fundId } = location.state || {};
  const [access, setAccess] = useState(false);
  const [trustee, setTrustee] = useState<Trustee[]>([]);
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        const trusteeList = (await dispatch(
          getTrustessList({ isActive: true })
        )) as unknown as Trustee[];
        setTrustee(trusteeList);
        const fundManagerList = (await dispatch(
          getFundManagersList({ isActive: true })
        )) as unknown as FundManager[];
        setFundManager(fundManagerList);

        const res = (await dispatch(
          getDistributorsListForFilters()
        )) as unknown as DistributorListType[];
        setDistributorList(res);

        if (fundId) {
          const GetFundDetail = (await dispatch(
            getPlanList(fundId.toString())
          )) as unknown as FundProps;
          const {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            defaulterPenalty,
          } = GetFundDetail || {};

          setEditField({
            ...editField,
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            defaulterPenalty,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: NewPlanType) => {
    try {
      const {
        schemeCode,
        schemeName,
        amcCode,
        bankName,
        bankBranch,
        ifscCode,
        bankAccountNumber,
        defaulterPenalty,
      } = values;

      const {
        planCode,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        managementFee,
        fundManagerId,
        trusteeId,
        tcLink,
        fundTenure,
        feeType,
        category,
        catchup,
        additionalReturnWithoutCatchup,
        hurdleRateOfReturn,
        addReturn,
        hurdleRate,
        distributorIds,
        isApplicableForDirect,
        exitLoad,
      } = values;
      if (fundId) {
        await dispatch(
          updateFund(fundId, {
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            defaulterPenalty,
          })
        );
      } else {
        const fundRes = (await dispatch(
          addFund({
            schemeCode,
            schemeName,
            amcCode,
            bankName,
            bankBranch,
            ifscCode,
            bankAccountNumber,
            defaulterPenalty,
          })
        )) as unknown as FundProps;

        await dispatch(
          addPlan({
            schemeId: fundRes.id,
            planCode,
            planDescription,
            schemeNature,
            fundTenure: fundTenure ? fundTenure : 0,
            feeType,
            category,
            catchup,
            hurdleRateOfReturn: hurdleRate === 'Yes' ? hurdleRateOfReturn : 'N/A',
            additionalReturnWithoutCatchup:
              addReturn === 'Yes' ? additionalReturnWithoutCatchup : 'N/A',
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: setupFee ? setupFee : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            tcLink,
            distributorIds,
            isApplicableForDirect,
            exitLoad,
          }) as unknown as ClassPlanProps
        );
      }
      history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={editField}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={
          fundId ? editFundValidation : addFundValidation(trustee, fundManager, distributorList)
        }>
        {({ handleSubmit, values, errors, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(fundId ? 'fund-details' : 'fund-list', { fundId: fundId })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {fundId ? 'Edit Fund' : 'Add Fund'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                      // backgroundColor: 'red',
                    }}>
                    {'Fund Details(Scheme)'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeCode"
                      label="Scheme Code *"
                      placeholder="Enter Scheme Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeName"
                      label="Scheme Name *"
                      placeholder="Enter Scheme Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="amcCode" label="AMC Code" placeholder="Enter AMC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="bankName" label="Bank Name" placeholder="Enter Bank Name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankBranch"
                      label="Bank Branch"
                      placeholder="Enter Bank Branch"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="ifscCode" label="IFSC Code" placeholder="Enter IFSC Code" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="bankAccountNumber"
                      label="Bank Account Number"
                      placeholder="Enter Bank Account Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="defaulterPenalty"
                      label="Defaulter penalty"
                      placeholder="Defaulter penalty"
                    />
                  </Grid>
                </Grid>
                {!fundId && (
                  <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                    <SubHeading
                      sx={{
                        color: 'common.blue',
                        letterSpacing: 0.5 + 'px',
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                        ml: { xs: 3.5 },
                      }}>
                      {'Plan Details'}
                    </SubHeading>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="planCode"
                        label="Plan Code *"
                        placeholder="Enter Plan Code"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="planDescription"
                        label="Plan Description *"
                        placeholder="Enter Plan Description"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name={`schemeNature`}
                        label="Scheme Nature *"
                        items={SchemeNatureMaster.map((item) => ({
                          key: item,
                          value: item,
                        }))}
                        onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                          if (value === 'Open') {
                            setValues({
                              ...values,
                              // ...initialValues,
                              schemeNature: value as string,
                              minContributionPercentage: 100,
                              category: 'full',
                            });
                          } else {
                            setValues({
                              ...values,
                              // ...initialValues,
                              schemeNature: value as string,
                              minContributionPercentage: 0,
                              category: '',
                            });
                          }
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="registrationNumber"
                        label="Registration Number *"
                        placeholder="Enter Registration Number"
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4} sx={{ pt: '25px !important' }}>
                      <UseRadioGroup
                        formLabel="Category *"
                        name={`category`}
                        defaultValue="full"
                        items={getCategoryMaster(values.schemeNature).map((category) => ({
                          label: category,
                          value: PlansCategoryMasters[category],
                        }))}
                        // disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ pt: '25px !important' }}>
                      <UseRadioGroup
                        formLabel="Fee Type *"
                        name={`feeType`}
                        defaultValue="Fixed"
                        items={Object.keys(PlansFeeTypeMaster).map((feeType) => ({
                          label: feeType,
                          value: PlansFeeTypeMaster[feeType],
                        }))}
                        // disabled={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name={`catchup`}
                        label="Catchup-With/without *"
                        items={CatchupMaster.map((item) => ({
                          key: item,
                          value: item,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="fundManagerId"
                        label="Investment Manager Name *"
                        items={fundManager.map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFSelectField
                        name="trusteeId"
                        label="Trustee Name *"
                        items={trustee.map((item) => ({
                          key: item.name,
                          value: item.id,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundTenure"
                        label="Fund Tenure (Years)"
                        placeholder="Enter Fund Tenure (Years)"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="schemeCategory"
                        label="Scheme Category *"
                        placeholder="Enter Scheme Category"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField name="country" label="Country" placeholder="Enter Country" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField name="currency" label="Currency" placeholder="Enter Currency" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DatePicker
                        label={'Scheme Start Date'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 14,
                          fontWeight: 500,

                          color: 'rgba(0,0,0,0.7)',
                        }}
                        placeholder={'Enter Scheme Start Date'}
                        name={`schemeStartDate`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="lockInPeriod"
                        label="Lock In Period(In Year)"
                        placeholder="Enter Lock In Period(In Year)"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="lockInExtension"
                        label="Lock In Extension(In Year)"
                        placeholder="Enter Lock In Extension(In Year)"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="faceValue"
                        label="Face value"
                        placeholder="Enter Face value"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress1"
                        label="Fund Address 1"
                        placeholder="Enter Fund Address 1"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress2"
                        label="Fund Address 2"
                        placeholder="Enter Fund Address 2"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="fundAddress3"
                        label="Fund Address 3"
                        placeholder="Enter Fund Address 3"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ pt: '25px !important' }}>
                      <UseRadioGroup
                        formLabel="Setup Fee Type"
                        name={`setupFeeType`}
                        defaultValue="Fixed"
                        items={Object.keys(PlansSetupFeeTypeMasters).map((feetype) => ({
                          label: feetype,
                          value: PlansFeeTypeMaster[feetype],
                        }))}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="setupFee"
                        label="Setup Fee"
                        placeholder="Enter Setup Fee"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="custodian"
                        label="Custodian"
                        placeholder="Enter Custodian"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="minCommitmentAmount"
                        label="Minimum Commitment Amount *"
                        placeholder="Enter Minimum Commitment Amount"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                      {values.minCommitmentAmount != 0 && (
                        <Typography sx={{ fontSize: 13 }}>
                          {currencyConversion(values.minCommitmentAmount)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="maxCommitmentAmount"
                        label="Maximum Commitment Amount *"
                        placeholder="Enter Maximum Commitment Amount"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                      {values.maxCommitmentAmount != 0 && (
                        <Typography sx={{ fontSize: 13 }}>
                          {currencyConversion(values.maxCommitmentAmount)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="minContributionPercentage"
                        label="Minimum Contribution Percentage *"
                        placeholder="Enter Minimum Contribution Percentage"
                        type="number"
                        disabled={values.schemeNature === 'Open'}
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="managementFee"
                        label="Management Fee"
                        placeholder="Enter Management Fee"
                        type="number"
                        onKeyDown={(e) => {
                          allowOnlyNumbers(e);
                        }}
                        trimOnBlur={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MFTextField
                        name="tcLink"
                        label={`PPM Link *`}
                        placeholder={`Enter PPM Link`}
                      />
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-between',
                        width: '100%',
                        mx: 4,
                        mt: 2,
                      }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography
                          sx={{
                            fontFamily: 'Work Sans,sans-serif',
                            fontWeight: 400,
                            fontSize: '15px',
                          }}
                          component="span">
                          {`Additional Return (%) *`}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <UseRadioGroup
                            name={`addReturn`}
                            formLabel="Is Applicable"
                            defaultValue={'No'}
                            items={additionalReturnWithoutCatchupMaster.map((feetype) => ({
                              label: feetype,
                              value: feetype,
                            }))}
                          />
                        </Box>
                        {values.addReturn === 'Yes' && (
                          <MFTextField
                            name="additionalReturnWithoutCatchup"
                            placeholder="Enter Additional Return (%)"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ pl: { xs: 0, sm: '26px' }, mt: { xs: 2, sm: 0 } }}>
                        <Typography
                          sx={{
                            fontFamily: 'Work Sans,sans-serif',
                            fontWeight: 400,
                            fontSize: '15px',
                          }}
                          component="span">
                          {`Hurdle rate of return p.a. (%) *`}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <UseRadioGroup
                            name={`hurdleRate`}
                            formLabel="Is Applicable"
                            defaultValue={'No'}
                            items={additionalReturnWithoutCatchupMaster.map((feetype) => ({
                              label: feetype,
                              value: feetype,
                            }))}
                          />
                        </Box>
                        {values.hurdleRate === 'Yes' && (
                          <MFTextField
                            name="hurdleRateOfReturn"
                            placeholder="Enter Hurdle rate of return p.a. (%)"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ pl: { xs: 0, sm: '28px' }, mt: { xs: 2, sm: 0 } }}>
                        <Typography
                          sx={{
                            fontFamily: 'Work Sans,sans-serif',
                            fontWeight: 400,
                            fontSize: '15px',
                          }}
                          component="span">
                          Exit Load
                        </Typography>
                        <MFTextField
                          name="exitLoad"
                          multiline
                          rows={4.5}
                          // label={`Exit Load`}
                          placeholder={`Enter Exit Load`}
                        />
                      </Grid>
                      {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ pl: { xs: 0, sm: '42px' }, mt: 3, display: 'flex' }}>
                        <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
                        <MFCheckbox
                          name={`isApplicableForDistributor`}
                          label="Distributor"
                          onChange={({ target: { checked } }) => {
                            setValues({
                              ...values,
                              isApplicableForDistributor: checked,
                              distributorIds: [],
                            });
                          }}
                          checked={values.isApplicableForDistributor}
                        />
                      </Grid> */}
                    </Box>
                    <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
                      <Box sx={{ display: 'flex' }}>
                        <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
                        <MFCheckbox
                          name={`isApplicableForDistributor`}
                          label="Distributor"
                          onChange={({ target: { checked } }) => {
                            setValues({
                              ...values,
                              isApplicableForDistributor: checked,
                              distributorIds: [],
                            });
                          }}
                          checked={values.isApplicableForDistributor}
                        />
                      </Box>
                      {values.isApplicableForDistributor && (
                        <MultipleSelect
                          name="distributorIds"
                          label="Applicable Distributor(s) *"
                          items={distributorList.map((item) => ({
                            key: item.id,
                            value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                          }))}
                          renderText="Selected Distributor(s)"
                        />
                      )}
                    </Grid>
                  </Grid>
                )}

                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
