import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Applicant, BrokerList } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { KeyValue } from '../ApplicationDetails/contributor';
import { Gridstyles } from '../ApplicationDetails';
import { SubHeading } from '../investors/components';
import { currencyConversion, getclIdField, getdpIdField } from '../../utils/utilityFunctions';
import { investorTypeMasters, DLCLMasters } from '../../utils/constant';
import { useDispatch } from 'react-redux';
import { getBrokers } from '../../redux-store/actions/application';
import { DematDeclarationPreview } from '../ApplicationDetails/kycDetails';

export default function ContributorDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  const dispatch = useDispatch();
  const [brokerList, setBrokerList] = useState<BrokerList>();
  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getBrokers())) as unknown as BrokerList;
        setBrokerList(response as BrokerList);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Contributor Details
              </Typography>
            </Grid>
            {applicants.map((applicant) => {
              return (
                <React.Fragment key={applicant.id}>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name Of Entity'} description={applicant.name} />
                      <KeyValue
                        title={'Date of Registration/Incorporation'}
                        description={formatShortDate(applicant.dateOfBirth)}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      <KeyValue
                        title={'Place of Registration/Incorporation'}
                        description={applicant.cityOfIncorporation}
                        sx={{ overflowWrap: 'anywhere' }}
                      />
                      <KeyValue
                        title={'CKYC No./CKYC Acknowledge No./KIN No'}
                        description={applicant.ckycNo}
                      />
                      {applicant.investorType && (
                        <KeyValue
                          title={'Investor Type'}
                          description={investorTypeMasters[applicant.investorType]}
                        />
                      )}
                      <KeyValue title={'PAN'} description={applicant.panNo} />
                    </Grid>
                  </Gridstyles>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {'Other Details'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={21}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={applicant.grossAnnualIncome}
                      />
                      <KeyValue
                        title={'Net Worth'}
                        description={applicant.netWorth}
                        amount={applicant.netWorth ? currencyConversion(applicant.netWorth) : 0}
                      />
                      <KeyValue
                        title={'PEP Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                      <KeyValue
                        title={'Demat Account Details (for allotment of units)'}
                        description={DLCLMasters[applicant.dlclId || '']}
                      />
                      {getdpIdField(applicant.dlclId || '') && (
                        <KeyValue title={'DP ID'} description={applicant.dpId} />
                      )}
                      {getclIdField(applicant.dlclId || '') && (
                        <KeyValue title={'CL ID'} description={applicant.clId} />
                      )}
                      {getdpIdField(applicant.dlclId || '') && (
                        <KeyValue
                          title={'Name of Broker/Depository Participant (DP)'}
                          description={
                            brokerList?.broker_list.find(
                              (broker) => broker.key === applicant.nameOfBroker
                            )?.value
                          }
                        />
                      )}
                      <DematDeclarationPreview applicant={applicant} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
