import { DistributorListType, FundManager, Trustee } from './api-types';

export const GET_FUNDS_LIST = 'GET_FUNDS_LIST';
export const GET_FUNDS_LIST_SUCCESS = 'GET_FUNDS_LIST_SUCCESS';

export const ADD_FUND = 'ADD_FUND';
export const ADD_FUND_SUCCESS = 'ADD_FUND_SUCCESS';

export const ADD_PLAN = 'ADD_PLAN';
export const ADD_PLAN_SUCCESS = 'ADD_PLAN_SUCCESS';

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';

export const GET_FUNDS_ACCESS = 'GET_FUNDS_ACCESS';
export const GET_FUNDS_ACCESS_SUCCESS = 'GET_FUNDS_ACCESS_SUCCESS';

export const UPDATE_FUND = 'UPDATE_FUND';
export const UPDATE_FUND_SUCCESS = 'UPDATE_FUND_SUCCESS';

export const GET_PLAN = 'GET_PLAN';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';

export const GET_PLAN_DETAILS = 'GET_PLAN_DETAILS';
export const GET_PLAN_DETAILS_SUCCESS = 'GET_PLAN_DETAILS_SUCCESS';

export type GetFundsListResponseBody = {
  funds: FundProps[];
};

export type FundProps = {
  amcId: number;
  schemeCode: string;
  schemeName: string;
  amcCode: string;
  bankName: string;
  bankBranch: string;
  ifscCode: string;
  bankAccountNumber: string;
  isActive: boolean;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  plans: ClassPlanProps[];
  id: string;
  [key: string]: string | number | boolean | ClassPlanProps[] | undefined;
  defaulterPenalty: string;
};

export type accessType = {
  fundSetupAccess: boolean;
};

export type addFundType = {
  bankAccountNumber: string;
  schemeCode: string;
  schemeName: string;
  amcCode: string;
  bankName: string;
  bankBranch: string;
  ifscCode: string;
  defaulterPenalty: string;
};

export type addPlanType = {
  schemeId: number | string | null;
  planCode: string;
  planDescription: string;
  fundTenure: string | null | number;
  feeType: string;
  // setupFeeType: string;
  category: string;
  catchup: string;
  additionalReturnWithoutCatchup: string;
  hurdleRateOfReturn: string;
  schemeNature: string;
  registrationNumber: string;
  schemeCategory: string;
  country: string;
  currency: string;
  schemeStartDate: string;
  lockInPeriod: string;
  lockInExtension: string;
  inActive: boolean;
  faceValue: number | null;
  fundAddress1: string;
  fundAddress2: string;
  fundAddress3: string;
  isActive: boolean;
  custodian: string;
  setupFee: number | null;
  fundManagerId: number | null;
  trusteeId: number | null;
  minCommitmentAmount: number | null;
  maxCommitmentAmount: number | null;
  minContributionPercentage: number | null;
  managementFee: number | null;
  tcLink: string;
  distributorIds: string[] | number[] | null;
  isApplicableForDirect: boolean;
  exitLoad: string;
};

export type addFundPlanType = {
  schemeCode: string;
  schemeName: string;
  fundTenure: string | null | number;
  feeType: string;
  category: string;
  catchup: string;
  additionalReturnWithoutCatchup: string;
  hurdleRateOfReturn: string;
  amcCode: string;
  bankName: string;
  bankBranch: string;
  ifscCode: string;
  bankAccountNumber: string;
  id: string;
  schemeId: number | null;
  planCode: string;
  planDescription: string;
  schemeNature: string;
  registrationNumber: string;
  schemeCategory: string;
  country: string;
  currency: string;
  schemeStartDate: string;
  lockInPeriod: string;
  lockInExtension: string;
  inActive: boolean;
  faceValue: number | null;
  fundAddress1: string;
  fundAddress2: string;
  fundAddress3: string;
  isActive: boolean;
  custodian: string;
  setupFee: number | null;
  fundManagerId: number | null;
  trusteeId: number | null;
  minCommitmentAmount: number | null;
  maxCommitmentAmount: number | null;
  minContributionPercentage: number | null;
  managementFee: number | null;
  tcLink: string;
  distributorIds: string[] | number[] | null;
  isApplicableForDirect: boolean;
  defaulterPenalty: string;
  exitLoad: string;
};

export type DistributorDetailsInPlans = {
  distributorIds: string[] | number[];
  distributors: DistributorListType[];
};
export type ClassPlanProps = {
  id: string;
  schemeId: any;
  planCode: string;
  fundManagerId: number | null;
  trusteeId: number | null;
  planDescription: string;
  fundTenure: string;
  feeType: string;
  category: string;
  catchup: string;
  additionalReturnWithoutCatchup: string;
  hurdleRateOfReturn: string;
  schemeNature: string;
  registrationNumber: string;
  schemeCategory: string;
  country: string;
  fundManager: FundManager;
  trustee: Trustee;
  currency: string;
  schemeStartDate: string;
  lockInPeriod: string;
  lockInExtension: string;
  inActive: boolean;
  faceValue: number | null;
  fundAddress1: string;
  fundAddress2: string;
  fundAddress3: string;
  custodian: string;
  setupFee: number;
  minCommitmentAmount: number;
  maxCommitmentAmount: number;
  minContributionPercentage: number;
  managementFee: number | null;
  isActive: boolean;
  tcLink: string;
  createdBy: number | null;
  createdAt: string;
  updatedAt: string;
  distributorDetails: DistributorDetailsInPlans;
  distributorIds: string[] | number[] | null;
  isApplicableForDirect: boolean;
  exitLoad: string;
};

export const GET_FUNDS_SUCCESS = 'GET_FUNDS_SUCCESS';
export const GET_FUNDS = 'GET_FUNDS';

export const GET_DIRECTAPPLICABLEFUNDS_SUCCESS = 'GET_DIRECTAPPLICABLEFUNDS_SUCCESS';
export const GET_DIRECTAPPLICABLEFUNDS = 'GET_DIRECTAPPLICABLEFUNDS';
