export const GET_DISTRIBUTOR_CODES = 'GET_DISTRIBUTOR_CODES';
export const GET_DISTRIBUTOR_CODES_SUCCESS = 'GET_DISTRIBUTOR_CODES_SUCCESS';

export const GET_IFSC_DETAILS_SUCCESS = 'GET_IFSC_DETAILS_SUCCESS';
export const GET_IFSC_DETAILS = 'GET_IFSC_DETAILS';

export const GET_DISTRIBUTORS_SUCCESS = 'GET_DISTRIBUTORS_SUCCESS';
export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS';

export const GET_RMS = 'GET_RMS';
export const GET_RMS_SUCCESS = 'GET_RMS_SUCCESS';

export const GET_Countries_SUCCESS = 'GET_Countries_SUCCESS';
export const GET_Countries = 'GET_Countries';

export type IfscType = {
  BankAdd1: string;
  BankAdd2: string;
  BankAdd3: string;
  BankCity: string;
  BankName: string;
  BankPin: number;
  IFSCCode: string;
  IFSCOwnedBank: string;
  MicrNo: string;
};
export type mdmsCountriesList = {
  name: string;
  dial_code: string;
  code: string;
  nationality: string;
};
export type nationaliyType = {
  countries: mdmsCountriesList[];
  allCountries: mdmsCountriesList[];
};

export const GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS = 'GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS';
export const GET_DISTRIBUTORS_FOR_FILTERS = 'GET_DISTRIBUTORS_FOR_FILTERS';

export const GET_JOINTHOLDERLIST_SUCCESS = 'GET_JOINTHOLDERLIST_SUCCESS';
export const GET_JOINTHOLDERLIST = 'GET_JOINTHOLDERLIST';
